import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RateReviewIcon from '@mui/icons-material/RateReview';
import DropDownButtonless from "../Components/DropDownButtonless.js";
import SimpleGridShippedItems from "../Components/SimpleGridShippedItems.js";
import MessageSnackBar from '../Components/MessageSnackBar.js';

import { 
    Button, 
    Grid,
    Paper ,
    Typography } from '@mui/material';

const OrderDiscrepancies = (props) => {
    const userData = useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success');
     // ! this is the function that is called to open the message bar
     const handleMessageBarOpen = (message, severity) => {
        setMessageBarMessage(message);
        setMessageSeverity(severity);
        setMessageBarOpen(true);
      };
  
      const handleMessageBarClosing = () => {
        setMessageBarOpen(false);
      };
      //!SECTION

    React.useEffect(() => {
        getWarehouseList(userData.email);
    }, []);

    const getWarehouseList = async (user) => {
    var parameters = { accountcode: userData.accountCode}; 
    await axios
    .get(
        `/OperationsInventory/validwarehouselocations`,
        { headers: headers, params: parameters }
        )
        .then(function async(response) {
        setWarehouseList(response.data.message)
        })
        .catch(function (error) {
        if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");

        }
        if (error.response.status !== 401) {
            setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
        })
    };

    const [ warehouseList, setWarehouseList ] = React.useState([]);
        //Variables to send down
    const warehouseTitles = {
        dropDownTitle: "Select Warehouse",
        dropDownLabel: "Select Warehouse",
        dropDownHelperText: "",
        dropDownAlertMessage: "Inventory for  " ,
    };
    const plecibo = () => {
    };
    

    const getDataUrl = '/OrderDiscrepancies/getOrderDiscrepancies';
    const removeDataUrl = '/OrderDiscrepancies/removeOrderDiscrepancy';
    const itemMessage = 'Order Discrepancies';


    const [ viewItems, setViewItems ] = React.useState(false);
    const [ viewItemsMessage, setViewItemsMessage ] = React.useState(`View ${itemMessage}`);
    const [ selectedShippedWarehouse, setSelectedShippedWarehouse ] = React.useState('All');
    const selectedShippedWarehouseRef = React.useRef(selectedShippedWarehouse);

    const toggleItems = () => {
        if (viewItems){
            setViewItems(false);
            setViewItemsMessage(`View ${itemMessage}`);
        }else{
            // getWarehouseList(userData.email)
            setViewItems(true);
            setViewItemsMessage(`Hide ${itemMessage}`);
        };
    };

    //useRef to keep track of the selected warehouse
    React.useEffect(() => {
      selectedShippedWarehouseRef.current = selectedShippedWarehouse;
    }, [selectedShippedWarehouse]);

    const handleSelectedWarehouseItems = async (selection) => {
      setSelectedShippedWarehouse(selection);
      selectedShippedWarehouseRef.current = selection;
      handleGetitems(selection);
    };


    const visibleColumns = {
      id: false,
      serialNum: false,
      };

    const filterModel = {
      items: [
        { field: 'Order Discrepancy', operator: 'equals', value: 'In Stock' },
      ]
    };
    // some things are defined in the grid component
    const pinnedModel = {left: ['Item Number','Order Discrepancy']};
    const sortModel = [{ field: "Order Discrepancy"},];

    const [ itemsGridColumns ] = React.useState([
      {field: 'Item Number', headerName: 'Item Number', width: 120, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Order Discrepancy', headerName: 'Order Discrepancy', width: 170, editable: false, hide: false, headerAlign: 'center', align:'center'},
      {field: 'id', width:300,hide: true},
      {field: 'Receipt Status', headerName: 'Inbounding Status', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Current Status', headerName: 'Shipping Status', width: 170, editable: false, hide: false, headerAlign: 'center', align:'center'},
      {field: 'PO Number', headerName: 'PO Number', width: 250, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Item Name', headerName: 'Item Name', width: 250, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Ship To Number', headerName: 'Ship To Number', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Ship To Name', headerName: 'Ship To Name', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Ship To Address', headerName: 'Ship To Address', width: 225, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Issue Date', headerName: 'Issue Date', width: 125, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Tracking', headerName: 'Tracking', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Delivery Date', headerName: 'Delivery Date', width: 150, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Delivery Time', headerName: 'Time', width:125, editable: false, align:'center', headerAlign: 'center'},
      {field: 'Delivery Location', headerName: 'Delivery Location', width: 175, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Received By', headerName: 'Received By', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'Removed Item Status', headerName: 'Order Replenishment', width: 200, editable: false, headerAlign: 'center', align:'center'},
      {field: 'delete', hide:(userData.userRole<6), type: 'actions', align: 'center', headerAlign:'center', headerName: 'Reviewed', minWidth: 175,  
        getActions: (params) => [
          <GridActionsCellItem icon={<RateReviewIcon sx={{color:"#68a0d7", fontSize:30 }}/>} onClick={updateShippedItem(params.id)}  label="delete" />
        ]}
        ])
    const [ itemsGridData, setItemsGridData ] = React.useState([])

    const handleGetitems = (warehouse) => {
      var parameters = { accountcode: userData.accountCode};
      axios
        .get(
          `/Shipments/getShippedItems/${warehouse}`,
          { headers: headers, params: parameters}
        )
        .then(function async(response) {
          // console.log("handleGetASNs", response.data.message)
          setItemsGridData (response.data.message);

        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      };

      const updateShippedItem = React.useCallback( (info) => async () => {
        // console.log("updateShippedItem", info)
        var itemId = info.toString();
        var warehouse = selectedShippedWarehouseRef.current;
        // console.log("removeShippedItem", item_id)
        // console.log("Headers", headers)
        // console.log("handleSelectedWarehouseremoved", selectedShippedWarehouseRef.current)
        axios
        .patch(
          `/Shipments/updateshippeditem/`,
          { itemId: itemId },
          { headers: headers }
        )
        .then(function async(response) {
          // handle success
          // console.log("updateShippedItem", response.data.message)
          setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
          setMessageBarMessage(response.data.message);
          setMessageBarOpen(true);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
        handleGetitems(warehouse);
        });

      },[]);

  return (  

        <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ alignContent: "center", border: 0 }}
      >
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
        >
          <Button onClick={toggleItems}>
            {" "}
            {viewItemsMessage}
          </Button>

          {/* ASN Filters */}
          {viewItems && 
          <>
        <Grid
          container
          justifyContent={"center"}
          sx={{marginBottom:4, marginTop:2}}
          >
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <DropDownButtonless
                itemList={warehouseList}
                handleSelectedItem={handleSelectedWarehouseItems}
                dropDownTitles={warehouseTitles}
                toggleClose={plecibo}
            />
          </Grid>

          <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ alignContent: "center", border: 0 }}
          >
          <SimpleGridShippedItems 
          gridColumns={itemsGridColumns}
          gridData={itemsGridData}
          visibleColumns={visibleColumns}
          sortModel={sortModel}
          filterModel={filterModel}
          pinnedModel={pinnedModel}
           />
          </Grid>
      </>
    }
        </Paper>
      </Grid>
  </>


  );
};
export default OrderDiscrepancies;