import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';

import * as Sentry from '@sentry/react';



import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EdonLogo from './edonLogoWhite.png'
import Button from '@mui/material/Button';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import ComputerImage from './img/upload.jpg'
import HeaderMenus from './Components/HeaderMenus.js';
// import UserStats from './Components/UserStats.js';
import Copyright from './Components/Copyright';
import CreateAccount from './Components/CreateAccount.js'
import DropDownButtonless from "./Components/DropDownButtonless.js";


import MemberStats from './Components/MemberStats.js';
import AccountStats from './Components/AccountStats.js';
import PlatformUsersTable from './Components/PlatformUsersTable.js';
import MobileUsersTable from './Components/MobileUsersTable.js';
// import AccountActivityStats from './Components/AccoutActivityStats.js';
import CreateUser from './Components/CreateUser.js';
import CreateMobileUser from './Components/CreateMobileUser.js';
import CreateWarehouseLocation from './Components/CreateWarehouseLocation.js';
import CreateProduct from './Components/CreateProduct.js';
import CurrentWarehousesTable from './Components/CurrentWarehousesTable.js'
import CurrentItemsTable from './Components/CurrentItemsTable.js';
import CurrentMarketsTable from './Components/CurrentMarketsTable';
import CurrentWarehouseShelfTable from './Components/CurrentWarehouseShelfTable.js';
import CurrentMobileUserAccess from './Components/CurrentMobileUserAccess.js';
import CreateRegion from './Components/CreateRegion';
import CreateMarkets from './Components/CreateMarkets';
import CreateShelf from './Components/CreateShelf';
import CurrentServicesTable from './Components/CurrentServicesTable.js';
import WALogo from './WA_logo.png'
import FlexLogo from './flexe_Logo.png';



import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './Components/MessageSnackBar';





// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    // mode: 'light',
    mode: 'dark',
  },
});


//! Edon Connect services component
function EdonConnectServices(props) {
  const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const headings = [
    "Nothing",
    "Data Upload",
    "Network Optimization",
    "Inventory Optimization",
    "Verify",
    "Demand Analyzer",
    "Simulator",
    "Connections",
    "Partners",
    "Your Resources",
    "Execution",
    "Replenishment",
    "Fullfillment",
    "Evaluation Dashboard",
    "Blockchain",
    "Integrations",
  ]
  
  const content = [
    "Nothing",
    "You can upload your own order data to start planning or upload our test data to see how it works.",
    "Use your data to determine where your inventory and assets need to be with the service level your customer expect.",
    "Find the right inventory placement based on your budget and locations.",
    "You can verify the expected results of specific network and inventory optimization models",
    "Discover signals from your historical order data or build what-if demand models with the demand analyzer.",
    "Bring the network, inventory and demand models together and simulate the cost and service level probablities.",
    "Connect to partners to help or provide help for others",
    "If you need a partner to perform, we will connect you to the right partners in the your markets",
    "If you have services to provide, list them with us.  We will list them during optimization or on the connect page.",
    "We will help you stay connected when you execute your plan. The Demand Analyzer will track your current demand for possible opportunities for improvement.",
    "We will automate all of your replenishment orders according to your orders and inventory levels.",
    "We can help you order fulfillment and routing",
    "Make sure that your supply chain is working as expected. If not, find out why and possible corrective actions.",
    "Participate in a supply chain blockchain application to reduce accounting reconcilation cost, improve forecasting and added services to your customers.  ",
    "Integrate Edon Connect to your ERP, WMS and other systems.",
    
  ]

  return (
    <Grid container spacing={2}>
    {cards.map((card) => (
      <Grid item key={card} xs={12} sm={6} md={4}>
        <Card
          sx={{ height: '100%',border:1 ,display: 'flex', flexDirection: 'column' }}
          >
          <CardMedia
            component="img"
            sx={{
              // 16:9
              //pt: '56.25%',
              pt: '10%',
            }}
            image={ComputerImage}
            alt="computerimage"
            />

          <CardContent sx={{ flexGrow: 1 }}>
            <Typography gutterBottom variant="h5" component="h2">
              {headings[card]}
            </Typography>

            <Typography>
              {content[card]}
            </Typography>
          </CardContent>
          {false &&
          <CardActions>
            <Button size="small">View</Button>
          </CardActions>}
        </Card>
      </Grid>
    )
    
    )}
  </Grid>
  );
}


export default function Dashboard(props) {
  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
  const pageTitle = "Adminstrative Dashboard"
  const sentrytesting = false;

  //! Snackbar Message
  const [ snackBarMessage, setSnackBarMessage ] = React.useState('');
  const [ snackbarOpen, setSnackBarOpen ] = React.useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
    // toggleViewCreateUser();
  };
  

    //$ Components
    //$ Components
    //$ Components

    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success');

    // ! this is the function that is called to open the message bar
    const handleMessageBarOpen = (message, severity) => {
      setMessageBarMessage(message);
      setMessageSeverity(severity);
      setMessageBarOpen(true);
    };

    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION



    //& Create Member
    //& Create Member
    //& Create Member
    //SECTION View Create Member
    // const [ viewCreateMember, setViewCreateMember ] = React.useState(false);
    // const [ createMemberMessage, setCreateMemberMessage ] = React.useState('Create a new membership');
    // const toggleViewCreateMember = () => {
    //   if (viewCreateMember){
    //     setViewCreateMember(false);
    //     setCreateMemberMessage('Create a New Membership');
    //   }else{
    //     setViewCreateMember(true);
    //     setCreateMemberMessage('Hide Create a New Member');
    //   };
    // };
    
    //& Create Account
    //& Create Account
    //& Create Account
    //& Create Account
    //! View Create Account
    const [ viewCreateAccount, setViewCreateAccount ] = React.useState(false);
    const [ createAccountMessage, setCreateAccountMessage ] = React.useState('Create a New Account');
    const toggleViewCreateAccount = () => {
      if (viewCreateAccount){
        setViewCreateAccount(false);
        setCreateAccountMessage('Create a New Account');
      }else{
        setViewCreateAccount(true);
        setCreateAccountMessage('Hide Create Account');
      };
    };
    //!SECTION - View Create Account

    //& Create User
    //& Create User
    //& Create User


    //! Get a list of accounts
    //SECTION Axios getAccountList
    const [ accountList, setAccountList ] = React.useState([]);
    const getAccountList = () => {
      axios
        .get(`/Dashboard/accountlist`, { headers } )
        .then(function (response) {

          setAccountList(response.data);
          
        })
        .catch(function (error) {
          // handle error
          if (error.response.status === 401) {
            // console.log("It worked");

          }
        })
    }; 
    //!SECTION

    //SECTION View Create Platform User
    const [ emailSentMessage, setEmailSentMessage ] = React.useState();
    const [ viewCreateUser, setViewCreateUser ] = React.useState(false);
    const [ createUserMessage, setCreateUserMessage ] = React.useState('Create A New Platform User');
    const toggleViewCreateUser = () => {
      if (viewCreateUser){
        setViewCreateUser(false);
        setCreateUserMessage('Create A New Platform User');
      }else{
        // console.log(userData.userRole)
        if (userData.userRole === 10){
          getAccountList();
        }
        // getAccountList();

        setViewCreateUser(true);
        setCreateUserMessage('Hide Create Platform User');
      };
    };
    //!SECTION - View Create User

    //SECTION Axios Registration Email
    const sendUserInvite = async (receiveremail, accountcode, recipientFirstName) => {
      const userid = userData.email;
      // console.log("Account Code", accountcode)
      // const headers = props.headers;
      // console.log(props.headers)
      await axios(
        {
          method: 'post',
          url: `/notifications/registrationemail/${userid}/${accountcode}/${recipientFirstName}/${receiveremail}`, 
          headers: headers
        }
        )
      .then(function (response) {
        setMessageSeverity("success") // this needs to be tested 
        setSnackBarMessage(response.data); // a registration email has been sent to ${receiveremail}
        setSnackBarOpen(true) 
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        setEmailSentMessage(error.response.data.detail);
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          
        }
      })
      .then(function () {
        toggleViewCreateUser();
      });
    } 
    //!SECTION


    //SECTION  Create Mobile User
    const [ viewCreateMobileUser, setViewCreateMobileUser ] = React.useState(false);
    const [ createUserMobileMessage, setCreateUserMobileMessage ] = React.useState('Create A New Mobile User');
    const toggleViewCreateMobileUser = () => {
      if (viewCreateMobileUser){
        setViewCreateMobileUser(false);
        setCreateUserMobileMessage('Create A New Mobile User');
      }else{
        // console.log("User Data",userData)
        if (userData.userRole === 10){
          getAccountList();
        }
        setViewCreateMobileUser(true);
        setCreateUserMobileMessage('Hide Create Mobile User');
      };
    };

    //!SECTION - Create Mobile User


    //SECTION Create Warehouse Location
    // build the need state variables
    const [ viewCreateWarehouseLocation, setViewCreateWarehouseLocation ] = React.useState(false);
    const [ createWarehouseLocationMessage, setCreateWarehouseLocationMessage ] = React.useState('Create A Warehouse Location');
    const toggleViewCreateWarehouseLocation = () => {   
      if (viewCreateWarehouseLocation){
        setViewCreateWarehouseLocation(false);
        setCreateWarehouseLocationMessage('Create A Warehouse Location');
      }else{
        if (userData.userRole === 10){
          getAccountList();
        }
        getMarketList(userData.accountCode)
        setViewCreateWarehouseLocation(true);
        setCreateWarehouseLocationMessage('Hide Create Warehouse Location');
      };
    };
    //!SECTION - Create Warehouse Location

     //! Get a list of accounts
    //SECTION Axios getAccountList
    const [ marketList, setMarketList ] = React.useState([]);

    // console.log("Headers", props.headers)
    const getMarketList = (accountCode) => {
      // const userid = userData.email;
      axios
        .get(`/Dashboard/currentmarkets/${accountCode}`, { headers } )
        .then(function (response) {
          // console.log(response.data.message)
          setMarketList(response.data.message);
        })
        .catch(function (error) {
          // handle error
          if (error.response.status === 401) {
            // console.log("It worked");
            alert ("Your session has expired. Please login again.");
           
          }
        })
    }; 
    //!SECTION




    //SECTION Create Product 
    // build the need state variables
    const [ viewCreateProduct, setViewCreateProduct ] = React.useState(false);
    const [ createProductMessage, setCreateProductMessage ] = React.useState('Create A Product');
    const toggleViewCreateProduct = () => {   
      if (viewCreateProduct){
        setViewCreateProduct(false);
        setCreateProductMessage('Create A Product');
      }else{
        if (userData.userRole === 10){
          getAccountList();
        }
        setViewCreateProduct(true);
        setCreateProductMessage('Hide Create Product');
      };
    };

    //!SECTION - Create Product


    //& Members Table
    //SECTION Axios get all members
    const getMemberData = (email) => {
      // console.log("user", userData.email);
      
      const userid = userData.email;
      // const headers = props.headers;
      axios
        .get(`/Dashboard/allmembers/${userid}`, { headers } )
        .then(function (response) {
          // handle success
          setMembersDisplayData(response.data);
          //("Membership Stats:", response.data);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            // console.log("It worked");
            alert ("Your session has expired. Please login again.");
           
          }
        })
        .then(function () {
          // always executed
        });
    };
    //!SECTION
    //& Members Table
    //! View Membership Table
    const [ membersDisplayData, setMembersDisplayData ] = React.useState([]);
    const [ viewMemberTable, setViewMemberTable ] = React.useState(false);
    const [ memberTableMessage, setMemberTableMessage ] = React.useState('View Memberships');
    const toggleViewMemberTable = () => {
      // console.log("User", userData)
      if (viewMemberTable){
        setViewMemberTable(false);
        setMemberTableMessage('View Memberships');
      }else{
        getMemberData(userData.email)
        setViewMemberTable(true);
        setMemberTableMessage('Hide Membership');
      };
    };
    
    //& Account Table
    //SECTION Axios getallaccounts
    
    //! View Accounts Table
    const [ accountsDisplayData, setAccountsDisplayData ] = React.useState([]);
    const [ viewAccountsTable, setViewAccountsTable ] = React.useState(false);
    const [ memberAccountsMessage, setMemberAccountsMessage ] = React.useState('View Accounts');
    const toggleViewAccountsTable = () => {
      if (viewAccountsTable){
        setViewAccountsTable(false);
        setMemberAccountsMessage('View Accounts');
      }else{
        getAccountData(userData.email)
        setViewAccountsTable(true);
        setMemberAccountsMessage('Hide Accounts');
      };
    };
    
    const getAccountData = (email) => {
      // console.log("user", userData.email);
      const userid = userData.email;
      const headers = props.headers;
      
      axios.get(`/Dashboard/allaccounts/${userid}`, { headers } )
      .then(function (response) {
        // handle success
        setAccountsDisplayData(response.data)
        // console.log("Membership Stats:", response.data); 
        
      })
      .catch(function (error) {
        // handle error
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! Account info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }

    const deleteAccount = React.useCallback( (account) => async () => {
      const headers = props.headers;

      await axios.delete(`adminstration/deleteaccount/${account}`, { headers: headers })
      .then(function(response) {
        setSnackBarMessage(response.data.message);
        setSnackBarOpen(true);
        getAccountData();
      })
      .catch(function(error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
          
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      }
      );
    }, []);   



    //!SECTION


    //& Platform User Table
    //SECTION Axios get all users
    //! View Platform User Table
    const [ platformUsersDisplayData, setPlatformUsersDisplayData ] = React.useState([]);
    const [ viewPlatformUsersTable, setViewPlatformUsersTable ] = React.useState(false);
    const [ platformUsersMessage, setPlatformUsersMessage ] = React.useState('View Platform Users');


    const toggleViewPlatformUsersTable = () => {
      if (viewPlatformUsersTable){
        setViewPlatformUsersTable(false);
        setPlatformUsersMessage('View Platform Users');
      }else{

        getPlatformUserData(userData.email)
        setViewPlatformUsersTable(true);
        setPlatformUsersMessage('Hide Platform Users');
      };
    };

    const getPlatformUserData = () => {
      axios.get(`Dashboard/platformusers`, 
      { headers: headers})
      .then(function (response) {
        // handle success
        setPlatformUsersDisplayData(response.data)
        // console.log("User Stats:", response.data); 
        
      })
      .catch(function (error) {
        if (error.response.status === 401) {
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }


    const deletePlatformUser = React.useCallback( (email) => async () => {
      
      // console.log(props.headers)
      await axios.delete(`adminstration/deleteplatformuser/${email}`, { headers: headers })
      .then(function() {
        setSnackBarMessage(`User ${email} has been deleted`);
        setSnackBarOpen(true);
        getPlatformUserData();
      })
      .catch(function(error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.response.data.detail}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      }
      );
  
    }, []);   

    const makePlatformUserManager = React.useCallback( (email) => async () => {

      // console.log(props.headers)
      const data = {
        user: email,
      }
      
      await axios.put(`MobileAdmin/platformusermanager`,  data, { headers: headers })
      .then(function(response ) {

        setSnackBarMessage(response.data.message);
        setSnackBarOpen(true);
        getPlatformUserData();
      })
      .catch(function(error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.response.data.detail}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      }
      );
    }, []);   


    //!SECTION

    //& Mobile User Table
    //SECTION Display Mobile Users
    //! View User Table
    const [ mobileUsersDisplayData, setMobileUsersDisplayData ] = React.useState([]);
    const [ viewMobileUsersTable, setViewMobileUsersTable ] = React.useState(false);
    const [ mobileUsersMessage, setMobileUsersMessage ] = React.useState('View Mobile Users');
    const toggleViewMobileUsersTable = () => {
      if (viewMobileUsersTable){
        setViewMobileUsersTable(false);
        setMobileUsersMessage('View Mobile Users');
      }else{
        getMobileUserTable();
        setViewMobileUsersTable(true);
        setMobileUsersMessage('Hide Mobile Users');
      };
    };

    const getMobileUserTable = () => {
      // console.log("Headers", headers)
      axios.get(`MobileApp/mobileuserstable`, {headers: headers})
      .then(function (response) {
        // handle success
        // console.log("Mobile User:", response.data.mobileUsersTable); 
        setMobileUsersDisplayData(response.data.mobileUsersTable)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }

    const deleteMobileUser = React.useCallback( (email) => async () => {
      // console.log("deleteMobileUser", email)

      // var headers = props.headers;
      // console.log(data)
      // console.log(props.headers)
      // await axios.delete(`MobileAdmin/deletemobileuser`, { data: data, headers: headers})
      await axios.delete(`MobileAdmin/deletemobileuser/${email}`, {headers: headers})
      .then(function() {
        setSnackBarMessage(`User ${email} has been deleted`);
        setSnackBarOpen(true)
        setMessageSeverity("success")
        getMobileUserTable();
      })
      .catch(function(error) {
        // console.log("error", error)
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setSnackBarMessage(`Oh no! User ${email} was not deleted. ${error.message}`);
          setSnackBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }

      }
      );
  
    }, []);   
 //!SECTION Display Mobile Users

 //SECTION - Assign Mobile User to Manager

 //!SECTION Assign Mobile User to Manager

 const [ viewMobileUserManager, setViewMobileUserManager ] = React.useState(false);

 // build a state variable to hold the message to display on the button
const [ mobileUserManagerMessage, setMobileUserManagerMessage ] = React.useState('Assign Mobile User to Manager');
const [ selectedManager, setSelectedManager ] = React.useState('');
// build a toggle function to change the state of the view variable
const toggleViewMobileUserManager = () => {   
  if (viewMobileUserManager){ 
    setViewMobileUserManager(false);
    setMobileUserManagerMessage('Assign Mobile User to Manager');
  }else{
    getMobileUsersList(userData.accountCode);
    getMobileManagersList(userData.accountCode);
    if (userData.userRole === 10){
      getAccountList();
    }
    setViewMobileUserManager(true);
    setMobileUserManagerMessage('Hide Mobile User Assignment');
  };
};

const handleSelectedManager = (event) => {
  // console.log("handleSelectedManager", event)
  setSelectedManager(event);
  // console.log("selectedManager", selectedManager);
  };

const mobileUserManagerTitles = {
  dropDownTitle: "",
  dropDownLabel: "Select Manager",
  dropDownHelperText: "Manager",
  dropDownAlertMessage: "Manager for  " ,
};

const [ mobileManagersList, setMobileManagersList ] = React.useState([]);
const getMobileManagersList = (account) => {

  // var account = "0x3190920299e"
  axios
    .get(`Dashboard/platformusersmanagers/${account}`, { headers } )
    .then(function (response) {
      // console.log("Mobile Managers:", response.data.message);
      setMobileManagersList(response.data.message);
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
    });
}; 


//build fuction to assign mobile user to manager
const handleAssignMobileUserToManager = () => { 
  // console.log("assignMobileUserToManager", email)
  var data = {
    accountCode: selectedAccount,
    mobileUser: selectedMobileUser,
    manager: selectedManager
  };
  // console.log("data", data)

  // console.log()
  axios
    .put(`/MobileAdmin/assignmobileusermanager`, data, { headers: headers } )
    .then(function(response) {
      setSnackBarMessage(response.data.message);
      setSnackBarOpen(true)
      toggleViewMobileUserManager();

    })
    .catch(function(error) {
      // console.log("error", error)
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status !== 401) {
        // console.log("error", error.response.data.detail)
        var errorMsg = error.response.data.detail;
        // console.log("error", errorMsg);

        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        setSnackBarMessage(`Oh no! Something went wrong. ${errorMsg}`);
        setMessageBarOpen(true);
      }
      });
    };


 //SECTION - Grant Warehouse Access to Mobile User
   
  const [ viewWarehouseMobileUser, setViewWarehouseMobileUser ] = React.useState(false);
  const [ warehouseMobileUsersMessage, setWarehouseMobileUsersMessage ] = React.useState('View/Grant Warehouse Access to Mobile User');

  const toggleViewWarehouseMobileUser = () => {
    if (viewWarehouseMobileUser){
      setViewWarehouseMobileUser(false);
      setWarehouseMobileUsersMessage('View/Grant Warehouse Access to Mobile User');
      setViewCurrentWarehouseAccess(false);
      setCurrentWarehouseAccessButton(false);
      setAssignWarehouseButton(true);
      setSelectedMobileUser();
      setSelectedWarehouse();
    }else{
      getWarehouseList(userData.accountCode);
      getMobileUsersList(userData.accountCode);
      if (userData.userRole === 10){
        getAccountList();
      }
      setViewWarehouseMobileUser(true);
      setWarehouseMobileUsersMessage('Hide View/Grant Warehouse Access');
    };
  };


  const removeWarehouseAccess = React.useCallback( (warehouse, mobileUser) => async () => {
    // var headers = props.headers;
    var data = {
      mobileUser: mobileUser,
      accountCode: selectedAccount,
      warehouseId: warehouse
    }

    await axios.put(`MobileAdmin/removewarehouseaccessmobileuser/`, data, { headers: headers })

    .then(function(response) {

      setViewWarehouseMobileUser(false);
      setWarehouseMobileUsersMessage('Grant Warehouse Access to Mobile User');
      setViewCurrentWarehouseAccess(false);
      setCurrentWarehouseAccessButton(false);
      setAssignWarehouseButton(true);
      setViewCurrentWarehouseAccess(false);
      setCurrentWarehouseAccessMessage('Current Warehouse Access');
      
      setSnackBarMessage(response.data.message);
      setSnackBarOpen(true);
      
    })
    .catch(function(error) {
      toggleViewWarehouseMobileUser();

      setViewWarehouseMobileUser(false);
      setWarehouseMobileUsersMessage('Grant Warehouse Access to Mobile User');
      setViewCurrentWarehouseAccess(false);
      setCurrentWarehouseAccessButton(false);
      setAssignWarehouseButton(true);
      setViewCurrentWarehouseAccess(false);
      setCurrentWarehouseAccessMessage('Current Warehouse Access');

      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");

      }
      if (error.response.status !== 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        toggleViewWarehouseMobileUser();
      }
    });
  }, []);   


  const [ mobileUsersList, setMobileUsersList ] = React.useState([]);
  const [ selectedAccount, setSelectedAccount ] = React.useState(userData.accountCode);
  const [ selectedMobileUser, setSelectedMobileUser ] = React.useState();
  const [ selectedWarehouse, setSelectedWarehouse ] = React.useState();
  const [ mobileUserWarehouseAccess, setMobileUserWarehouseAccess ] = React.useState([]);
  const [ viewCurrentWarehouseAccessButton, setCurrentWarehouseAccessButton ] = React.useState(false);
  const [ viewAssignWarehouseButton, setAssignWarehouseButton ] = React.useState(true);
  
  //! Remove these
  // const [ viewRemoveWarehouseAccess, setViewRemoveWarehouseAccess ] = React.useState(false);
  // const [ removeWarehouseAccessMessage, setRemoveWarehouseAccessMessage ] = React.useState('Current Warehouse Access');

  const [ viewCurrentWarehouseAccess, setViewCurrentWarehouseAccess ] = React.useState(false);
  const [ currentWarehouseAccessMessage, setCurrentWarehouseAccessMessage ] = React.useState('Current Warehouse Access');


  //! need to change this to run only for the specific dropdown
  const handleAccountChange = (event) => {
    setSelectedAccount(event);
    getWarehouseList(event);
    getMobileUsersList(event);
    getMobileManagersList(event);
    getCurrentWarehousesTable(event);
    getCurrentItemsTable(event);
    getCurrentServicesTable(event);
    getCurrentMarketsTable(event);
    getWarehouseList(event);
    
  };

  
  const toggleCurrentWarehouseAccess = () => {
    //! This names should be changed to current instead of remove
    if (viewCurrentWarehouseAccess){
      setViewCurrentWarehouseAccess(false);
      setCurrentWarehouseAccessMessage('Current Warehouse Access');
    }else{
      setViewCurrentWarehouseAccess(true);
      setCurrentWarehouseAccessMessage('Hide Current Warehouse Access');
    };
  };
  

  const toggleCurrentWarehouseAccessButton = () => {
    if (viewCurrentWarehouseAccessButton){
      setCurrentWarehouseAccessButton(false);
    }else{
      setCurrentWarehouseAccessButton(true);
    };
  };
  //! is this needed
  // const toggleWarehouseAccessButton = () => {
  //   if (viewAssignWarehouseButton){
  //     setAssignWarehouseButton(false);
  //   }else{
  //     setAssignWarehouseButton(true);
  //   };
  // };

  const handleMobileUserSelect = (event) => {
    setSelectedMobileUser(event);
    toggleCurrentWarehouseAccessButton(); // this is the current warehouse button
    if (selectedWarehouse !== undefined){
      setAssignWarehouseButton(false);
    }
    getMobileUsersAccess(selectedAccount, event);
  };
  
  const handleSelectedWarehouse = (event) => {
    setSelectedWarehouse(event);
    //if selectedMobileUser is not undefined than setAssignWarehouseButton to false
    if (selectedMobileUser != undefined){
      setAssignWarehouseButton(false);
    }
    // setAssignWarehouseButton(false); //enable the assign button
  };

  const mobileUserTitles = {
    dropDownTitle: "",
    dropDownLabel: "Select Mobile User",
    dropDownHelperText: "Mobile User",
    dropDownAlertMessage: "Mobile for  " ,
  };

  const getMobileUsersList = (account) => {
    
    // console.log("Headers", headers)
    axios
      .get(`MobileAdmin/mobileUserList/${account}`, { headers } )
      .then(function (response) {
        // console.log("Mobile Users:", response.data.message);
        setMobileUsersList(response.data.message);
        
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
      });
  }; 

  const getMobileUsersAccess = (account,mobileUser) => {
    axios
      .get(`MobileAdmin/mobileUserWarehouseAccessList/${account}/${mobileUser}`, { headers } )
      .then(function (response) {
        // console.log("Mobile Users:", response.data.message);
        setMobileUserWarehouseAccess(response.data.message);
        // setMobileUsersList(response.data.message);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
      });
  }; 

  const handleAssignWarehouseToMobileUser = () => {
    // console.log("selectedAccount", selectedAccount)
    // console.log("Account Code", userData.accountCode)
    var data = {  
      mobileUser: selectedMobileUser,
      warehouseId: selectedWarehouse,
      accountCode: selectedAccount
    };
    // console.log("Data", data)
    
    axios
      .put(`/MobileAdmin/warehouseaccessmobileuser`, data, { headers: headers } )
      .then(function (response) {
        // console.log("Me", response.data.message)
        toggleViewWarehouseMobileUser();
        setSnackBarMessage(response.data.message);
        setSnackBarOpen(true);
        setMessageSeverity("success") // error: red, warning: orange, information: blue, success: green
        
      })
      .catch(function (error) { 
          toggleViewWarehouseMobileUser();
          if (error.response.status === 401) {
            // console.log("It worked");
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status != 401) {
            console.log("error", error.response)
            // var errorMsg = error.response.data.detail;
            setMessageBarMessage(`Oh no! Something went wrong.`)
            // console.log(error.response.data.detail)
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
      });
  };
 //!SECTION - Assign Warehouse to User




//SECTION Display Current Warehouse Locations
    const accountTitles = {
      dropDownTitle: "",
      dropDownLabel: "Select Account",
      dropDownHelperText: "Account",
      dropDownAlertMessage: "Account for  " ,
    };

    const warehouseMobileTitles = {
      dropDownTitle: "",
      dropDownLabel: "Warehouse",
      dropDownHelperText: "Warehouse",
      dropDownAlertMessage: "Mobile for  " ,
    };

    const [ currentWarehousesDisplayData, setCurrentWarehousesDisplayData ] = React.useState([]);
    const [ viewCurrentWarehousesTable, setViewCurrentWarehousesTable ] = React.useState(false);
    const [ currentWarehousesMessage, setCurrentWarehousesMessage ] = React.useState('View Warehouses');
    const toggleViewCurrentWarehousesTable = () => {
      if (viewCurrentWarehousesTable){
        setViewCurrentWarehousesTable(false);
        setCurrentWarehousesMessage('View Warehouses');
      }else{

        getCurrentWarehousesTable(selectedAccount);
        setViewCurrentWarehousesTable(true);
        setCurrentWarehousesMessage('Hide Warehouses');
        getAccountList();
      };
    };
    
    const getCurrentWarehousesTable = (account) => {
      axios.get(`Dashboard/currentwarehouselocations/${account}`, 
        {headers: headers})
      .then(function (response) {
        setCurrentWarehousesDisplayData(response.data.message)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }

    const deleteWarehouse = React.useCallback( (warehouse) => async () => {
      const headers = props.headers;
      // console.log(userData)
      await axios.delete(`Dashboard/deletecurrentwarehouselocations/${warehouse}`, { headers: headers })
      .then(function(response) {
        setSnackBarMessage(response.data.message);
        setSnackBarOpen(true);
        getCurrentWarehousesTable(userData.accountCode);
      })
      .catch(function(error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      }
      );
    }, []);   
  //!SECTION - Display Current Warehouse Locations



  //SECTION Display Current items
    const [ currentItemsDisplayData, setCurrentItemsDisplayData ] = React.useState([]);
    const [ viewCurrentItemsTable, setViewCurrentItemsTable ] = React.useState(false);
    const [ currentItemsMessage, setCurrentItemsMessage ] = React.useState('View Products');
    const toggleViewCurrentItemsTable = () => {
      if (viewCurrentItemsTable){
        setViewCurrentItemsTable(false);
        setCurrentItemsMessage('View Products');
      }else{

        getCurrentItemsTable(selectedAccount);
        setViewCurrentItemsTable(true);
        setCurrentItemsMessage('Hide Products');
        getAccountList();
      };
    };
    const getCurrentItemsTable = (account) => {
      // var headers = props.headers;
      // var accountCode = userData.accountCode;
      axios.get(`Dashboard/currentitems/${account}`, {headers: headers})
      .then(function (response) {
        // handle success
        // console.log("Current Items:", response.data.message); 
        setCurrentItemsDisplayData(response.data.message)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
 //!SECTION Display Current Items


  //SECTION Display Current services
    const [ currentServicesDisplayData, setCurrentServicesDisplayData ] = React.useState([]);
    const [ viewCurrentServicesTable, setViewCurrentServicesTable ] = React.useState(false);
    const [ currentServicesMessage, setCurrentServicesMessage ] = React.useState('View Services');
    
    const toggleViewCurrentServicesTable = () => {
      if (viewCurrentServicesTable){
        setViewCurrentServicesTable(false);
        setCurrentServicesMessage('View Services');
      }else{

        getCurrentServicesTable(selectedAccount);
        setViewCurrentServicesTable(true);
        setCurrentServicesMessage('Hide Services');
        getAccountList();
      };
    };
    const getCurrentServicesTable = (account) => {
      axios.get(`Dashboard/currentservices/${account}`, {headers: headers})
      .then(function (response) {
        // handle success
        // console.log("Current Markets:", response.data.message); 
        setCurrentServicesDisplayData(response.data.message)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! This is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
    const changeCurrentServices = React.useCallback( (service, status, account) => async () => {
      var headers = props.headers;
      if (status === "Active"){
        var addRemove = "remove"
        }else{  
          var addRemove = "add"
        }
      var data = {
        accountCode: account,
        services: [service],
        addRemove: addRemove
      }

      
      axios.put(`adminstration/addremoveaccountservices`, data, {headers: headers})
      .then(function (response) {
        
        setMessageBarMessage(`${response.data.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("success")
        getCurrentServicesTable(account);
        // handle success
        // console.log("Current Markets:", response.data.message); 
        // setCurrentServicesDisplayData(response.data.message)
      })
      .catch(function (error) {
        console.log(error.response)
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          console.log(error.response)
          setMessageBarMessage(`Sorry! This is not available at this time. ${error.response.data.detail}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }, []);
    


 //!SECTION Display Current Markets


  //SECTION Display Current Markets
    const [ currentMarketsDisplayData, setCurrentMarketsDisplayData ] = React.useState([]);
    const [ viewCurrentMarketsTable, setViewCurrentMarketsTable ] = React.useState(false);
    const [ currentMarketsMessage, setCurrentMarketsMessage ] = React.useState('View Markets');
    
    const toggleViewCurrentMarketsTable = () => {
      if (viewCurrentMarketsTable){
        setViewCurrentMarketsTable(false);
        setCurrentMarketsMessage('View Markets');
      }else{

        getCurrentMarketsTable(selectedAccount);
        setViewCurrentMarketsTable(true);
        setCurrentMarketsMessage('Hide Markets');
        getAccountList();
      };
    };
    const getCurrentMarketsTable = (account) => {

      axios.get(`Dashboard/currentmarkets/${account}`, {headers: headers})
      .then(function (response) {
        setCurrentMarketsDisplayData(response.data.message)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
 //!SECTION Display Current Markets



 //SECTION - Warehouse Shelfs
    //!Dropdown for Warehouses
    const [ warehouseList, setWarehouseList ] = React.useState([]);
    const warehouseTitles = {
      dropDownTitle: "Select Warehouse",
      dropDownLabel: "Select Warehouse",
      dropDownHelperText: "",
      dropDownAlertMessage: "Inventory for  " ,
    };

    const plecibo = () => {
    };

    const handleSelectedWarehouseInventory = async (selection) => {
      getWarehouseShelfsTable(selection);
    };

    const getWarehouseList = async (account) => {
      await axios
      .get(
        `Dashboard/currentwarehouselocationslist/${account}`,
        { headers: headers }
        )
        .then(function async(response) {
          // console.log("Warehouse Locations", response.data.message);
          setWarehouseList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    };
  


    //! warehouse shelfs
    const [ warehouseShelfsDisplayData, setWarehouseShelfsDisplayData ] = React.useState([]);
    const [ viewWarehouseShelfsTable, setViewWarehouseShelfsTable ] = React.useState(false);
    const [ warehouseShelfsMessage, setWarehouseShelfsMessage ] = React.useState('View Warehouse Shelfs');
    const toggleViewWarehouseShelfsTable = () => {
      if (viewWarehouseShelfsTable){
        setViewWarehouseShelfsTable(false);
        setWarehouseShelfsMessage('View Warehouse Shelfs');
      }else{
        getWarehouseList(userData.accountCode)
        setViewWarehouseShelfsTable(true);
        setWarehouseShelfsMessage('Hide Warehouse Shelfs');
        getAccountList();
      };
    };

    const getWarehouseShelfsTable = (warehouse) => {
      // console.log("Warehouse", warehouse)

      axios.get(`Dashboard/currentshelflist/${selectedAccount}/${warehouse}`, {headers: headers})
      .then(function (response) {
        // handle success
        // console.log("Warehouse Shelfs:", response.data.message);
        setWarehouseShelfsDisplayData(response.data.message)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
  //!SECTION - Warehouse Shelfs



    //& User Activity 
    //& User Activity 
    //& User Activity 
    //SECTION Axios get user activity
    const userStats = () => {
      const userid = userData.email;
      axios.get(`/adminstration/useractivitylist/${userid}`)
      .then(function (response) {
        // handle success
        setUserDisplayStats(response.data)
        // console.log("Membership Stats:", response.data); 
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status != 401) {
          setMessageBarMessage(`Sorry! User activity is not available at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
  // !SECTION


    //! View User Activity Table
    const [ setUserDisplayStats ] = React.useState([])
    // const [ viewUserActivityTable, setViewUserActivityTable ] = React.useState(false);
    // const [ userActivityMessage, setUserActivityMessage ] = React.useState('View User Activities');
    // const toggleViewUserActivityTable = () => {
    //   if (viewUserActivityTable){
    //     setViewUserActivityTable(false);
    //     setUserActivityMessage('View User Activities');
    //   }else{
    //     setViewUserActivityTable(true);
    //     setUserActivityMessage('Hide User Activities');
    //     userStats();
    //   };
    // };

    //& Account Activity 
    //& Account Activity 
    //& Account Activity 
    //SECTION Axios get account Activity
    const accountStats = () => {

      const userid = userData.email;
      axios.get(`/adminstration/accountactivitylist/${userid}`)
      .then(function (response) {
        // handle success
        setAccountDisplayStats(response.data)
        // console.log("Account Stats:", response.data); 
        
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`This is inconvenient, account activity is unavailable at this time. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
    //!SECTION 

    //! View User Activity Table
    const [ setAccountDisplayStats ] = React.useState([])
    // const [ viewAccountActivityTable, setViewAccountActivityTable ] = React.useState(false);
    // const [ accountActivityMessage, setAccountActivityMessage ] = React.useState('View Account Activities');
    // const toggleViewAccountActivityTable = () => {
    //   if (viewAccountActivityTable){
    //     setViewAccountActivityTable(false);
    //     setAccountActivityMessage('View Account Activities');
    //   }else{
    //     setViewAccountActivityTable(true);
    //     setAccountActivityMessage('Hide Account Activities');
    //     accountStats();
    //   };
    // };


       //!SECTION - Create Warehouse Location

    //SECTION Create Regions

    const [ viewCreateRegion, setViewCreateRegion ] = React.useState(false);
    const [ createRegionMessage, setCreateRegionMessage ] = React.useState('Create A Region');
    const toggleViewCreateRegion = () => {   
      if (viewCreateRegion){
        setViewCreateRegion(false);
        setCreateRegionMessage('Create A Region');
      }else{
        if (userData.userRole === 10){
          getAccountList();
        }
        setViewCreateRegion(true);
        setCreateRegionMessage('Hide Create Region');
      };
    };
    //!SECTION - Regions

    //SECTION Create Markets

    const [ viewCreateMarket, setViewCreateMarket ] = React.useState(false);
    const [ createMarketMessage, setCreateMarketMessage ] = React.useState('Create A Market');
    const toggleViewCreateMarket = () => {   
      if (viewCreateMarket){
        setViewCreateMarket(false);
        setCreateMarketMessage('Create A Market');
      }else{
        if (userData.userRole === 10){
          getAccountList();
        }
        // console.log("Account Codes", userData.accountCode)
        getRegionsList(userData.accountCode);
        setViewCreateMarket(true);
        setCreateMarketMessage('Hide Create Market');
      };
    };

    const [ regionList, setRegionList ] = React.useState([]);
    const getRegionsList = (account) => {
      // console.log("account", account);

      
      // console.log("headers", headers);

      axios.get(`/Dashboard/regionslist/${account}`, { headers } )
      .then(function (response) {
        // handle success
        setRegionList(response.data)

        // console.log("Membership Stats:", response.data); 
        
      })
      .catch(function (error) {
        // handle error
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Sorry! Account info is not available at this time. ${error.response.data.detail}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    }
    //!SECTION - Markets

    //SECTION Create Shelf

    const [ viewCreateShelf, setViewCreateShelf ] = React.useState(false);
    const [ createShelfMessage, setCreateShelfMessage ] = React.useState('Create A Shelf');
    const toggleViewCreateShelf = () => {   
      if (viewCreateShelf){
        setViewCreateShelf(false);
        setCreateShelfMessage('Create A Shelf');
      }else{
        if (userData.userRole === 10){
          getAccountList();
        }
        // console.log("Account Codes", userData.accountCode)
        getWarehouseList(userData.accountCode);
        setViewCreateShelf(true);
        setCreateShelfMessage('Hide Create Shelf');
      };
    };
    //!SECTION - Shelfs

    //& SERVICES
    //& SERVICES
    //& SERVICES
    //! View Services
    const [ viewEdonConnectServices, setViewEdonConnectServices ] = React.useState(false);
    const [ edonConnectServicesMessage, setEdonConnectServicesMessage ] = React.useState('Read about Edon Connect Services');
    const toggleEdonConnectServices = () => {
      if (viewEdonConnectServices){
        setViewEdonConnectServices(false);
        setEdonConnectServicesMessage('Read about Edon Connect Services');
      }else{
        setViewEdonConnectServices(true);
        setEdonConnectServicesMessage('Hide Edon Connect Services Information');
      };
    };
    //Testing Sentry Error 
    const triggerError = () => {
      try {
        alert("This is a test alert");
        throw new Error('Test Sentry Error');
        //do an alert
      } catch (error) {
        alert("No error", error);
        Sentry.captureException(error);
      }
    };


  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />


          {/* Content*/}
          {/* Content*/}
          {/* Content*/}

          {/* <img src={EdonLogo} width={200} alt="Logo" /> */}
        {userData.membershipCode === "0x12a6b5324" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            }
        {userData.membershipCode === "0x4ee11bfe" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            }
        {userData.membershipCode === "0xd4eed5a7" && //Warehouse Anywhere membership
          <Grid item sx={{pt:2}}>
              <img src={EdonLogo } width={200} alt="Logo" />
            </Grid>
        }
        
          {/* Button to test Sentry */}
        {sentrytesting &&  
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" onClick={triggerError}>
              Trigger Sentry Error
            </Button>
         </Grid>
        }
        <Container overflow="auto" maxWidth="xl" sx={{ mt:5, pt:2 ,mb: 10, border:1 }}>

          <Snackbar
              anchorOrigin = {{ 
                vertical: 'top',
                horizontal: 'center', 
              }}
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <MuiAlert elevation={6} variant="filled" severity={messageSeverity}>{snackBarMessage}</MuiAlert>
        </Snackbar>
        
          <Grid container sx={{justifyContent:"center", mb:2}} spacing={3}>

          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 30,
                  }}
                >
                  <Typography align='center' variant={'h4'}>{userData.userCompany} Dashboard </Typography>
                  <Typography align='center' variant={'subtitle1'}>{userData.email}</Typography>
                </Paper>
              </Grid>

                  {/* {(userData.membershipViewer && userData.membershipCode === '0xd4eed5a7') &&
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateMember}> {createMemberMessage} </Button> 
                  {viewCreateMember &&
                    <CreateMember toggleViewCreateMember={toggleViewCreateMember}  emailSentMessage={emailSentMessage} user={userData} headers={props.headers} />
                  }
                </Paper>
            </Grid>} */}

                  {(userData.accountViewer) &&
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateAccount} > {createAccountMessage} </Button> 
                  {viewCreateAccount &&
                    <CreateAccount  headers={props.headers} toggleViewCreateAccount={toggleViewCreateAccount} />
                  }
                </Paper>
              </Grid>
                }
                {/* Create Platform User */}
              {(userData.userRole > 5) &&
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateUser}> {createUserMessage} </Button> 
                  {viewCreateUser &&
                    <CreateUser emailSentMessage={emailSentMessage} toggleViewCreateUser={toggleViewCreateUser} sendUserInvite={sendUserInvite}  accountList={accountList} headers={props.headers} user={userData} handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>
              }
              {/* Create Mobile User */}
              {(userData.userRole > 4) &&
          
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateMobileUser}> {createUserMobileMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewCreateMobileUser &&
                    <CreateMobileUser emailSentMessage={emailSentMessage}  sendUserInvite={sendUserInvite}  accountList={accountList} headers={props.headers} user={userData} toggleViewCreateMobileUser ={toggleViewCreateMobileUser} handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>
              }

              {(userData.userRole > 3) &&
                  <>
              {/* Create Warehouse location */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateWarehouseLocation}> {createWarehouseLocationMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewCreateWarehouseLocation &&
                    <CreateWarehouseLocation toggleViewCreateWarehouseLocation={toggleViewCreateWarehouseLocation} marketList={marketList} getMarketList={getMarketList} accountList={accountList} headers={props.headers} user={userData} handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>

              {/* Create Product */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateProduct}> {createProductMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewCreateProduct &&
                    <CreateProduct toggleViewCreateProduct={toggleViewCreateProduct}   accountList={accountList} headers={props.headers} user={userData} handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>


              {/* Assign Mobile User to Manager */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewMobileUserManager}> {mobileUserManagerMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewMobileUserManager &&
                    <>
                    {(userData.userRole === 10) &&
                    <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                    }
                    <DropDownButtonless itemList={mobileUsersList} handleSelectedItem={handleMobileUserSelect} dropDownTitles={mobileUserTitles} toggleClose={plecibo}/>
                    <DropDownButtonless itemList={mobileManagersList} handleSelectedItem={handleSelectedManager} dropDownTitles={mobileUserManagerTitles} toggleClose={plecibo}/>
                    <Button
                      // disabled={buttonDisable}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleAssignMobileUserToManager}
                    > 
                    Assign Mobile User to Manager 
                    </Button>

                    </>
                  }
                </Paper>
              </Grid>

              {/* Assign Warehouse to Mobile User */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewWarehouseMobileUser}> {warehouseMobileUsersMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewWarehouseMobileUser &&
                    <>
                    {(userData.userRole === 10) &&
                    <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                    }
                    <DropDownButtonless itemList={mobileUsersList} handleSelectedItem={handleMobileUserSelect} dropDownTitles={mobileUserTitles} toggleClose={plecibo}/>
                    

                    <DropDownButtonless itemList={warehouseList} handleSelectedItem={handleSelectedWarehouse} dropDownTitles={warehouseMobileTitles} toggleClose={plecibo}/>
                    <Button
                      disabled={viewAssignWarehouseButton}
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleAssignWarehouseToMobileUser}
                      > 
                    Grant Warehouse Access to Mobile User 
                    </Button>

                      {viewCurrentWarehouseAccessButton &&
                      <Button onClick={toggleCurrentWarehouseAccess}> {currentWarehouseAccessMessage} </Button>
                      }
                      {viewCurrentWarehouseAccess &&
                      <CurrentMobileUserAccess selectedMobileUser={selectedMobileUser} removeWarehouseAccess={removeWarehouseAccess} mobileUserWarehouseAccess={mobileUserWarehouseAccess} />
                      }
                    </>
                  }
                </Paper>
              </Grid>


              </>
              }
              {/* Members Table */}
              {(userData.membershipViewer && userData.membershipCode === '0xd4eed5a7') &&
              <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewMemberTable}> {memberTableMessage} </Button> 
                  {viewMemberTable &&
                    <MemberStats membersDisplayData={membersDisplayData} />
                  }
                </Paper>
              </Grid>
              }
              {/* Accounts Table */}
              {(userData.accountViewer)&&
              <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewAccountsTable}> {memberAccountsMessage} </Button> 
                  {viewAccountsTable &&
                    <AccountStats deleteAccount={deleteAccount} accountsDisplayData={accountsDisplayData} />
                  }
                </Paper>
              </Grid>
              }
              {/* Platform Users Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewPlatformUsersTable}> {platformUsersMessage} </Button> 
                  {viewPlatformUsersTable &&
                    <PlatformUsersTable user = {userData} platformUsersDisplayData={platformUsersDisplayData} deletePlatformUser={deletePlatformUser} makePlatformUserManager={makePlatformUserManager}/>
                  }
                </Paper>
              </Grid>
              }
              {/* Mobile Users Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewMobileUsersTable}> {mobileUsersMessage} </Button> 
                  {viewMobileUsersTable &&
                    <MobileUsersTable user= {userData} mobileUsersDisplayData={mobileUsersDisplayData} deleteMobileUser={deleteMobileUser}/>
                  }
                </Paper>
              </Grid>
              }
            
              {/* Current Warehouses Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCurrentWarehousesTable}> {currentWarehousesMessage} </Button> 
                  {viewCurrentWarehousesTable &&
                  <>
                    {(userData.userRole === 10) &&
                      <Grid
                      container
                      justifyContent={"center"}
                      sx={{marginBottom:4, marginTop:2}}
                      >
                        <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                      </Grid>
                    }
                    <CurrentWarehousesTable user= {userData} currentWarehousesDisplayData={currentWarehousesDisplayData} deleteWarehouse={deleteWarehouse}/>
                  </>
                  }
                </Paper>
              </Grid>
              }
            
              {/* Current Items Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCurrentItemsTable}> {currentItemsMessage} </Button> 
                  {viewCurrentItemsTable &&
                  <>
                  {(userData.userRole === 10) &&
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                      <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                    </Grid>
                  }
                    <CurrentItemsTable user= {userData} currentItemsDisplayData={currentItemsDisplayData} />
                    </>
                  }
                </Paper>
              </Grid>
              }
              {/* Current Services Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCurrentServicesTable}> {currentServicesMessage} </Button> 
                  {viewCurrentServicesTable &&
                  <>
                  {(userData.userRole === 10) &&
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                      <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                    </Grid>
                  }
                    <CurrentServicesTable user= {userData} currentServicesDisplayData={currentServicesDisplayData} selectedAccount={selectedAccount} changeCurrentServices={changeCurrentServices} />
                    </>
                  }
                </Paper>
              </Grid>
              }
              {/* Current Markets Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCurrentMarketsTable}> {currentMarketsMessage} </Button> 
                  {viewCurrentMarketsTable &&
                  <>
                  {(userData.userRole === 10) &&
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                      <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                    </Grid>
                  }
                    <CurrentMarketsTable user= {userData} currentMarketsDisplayData={currentMarketsDisplayData} />
                    </>
                  }
                </Paper>
              </Grid>
              }

              {/* Current Warehouse Shelfs Table */}
              {(userData.userRole > 3)&&
                <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewWarehouseShelfsTable}> {warehouseShelfsMessage} </Button> 
                  {viewWarehouseShelfsTable &&
                    <>
                  {(userData.userRole === 10) &&
                    <Grid
                    container
                    justifyContent={"center"}
                    sx={{marginBottom:4, marginTop:2}}
                    >
                    <DropDownButtonless itemList={accountList} handleSelectedItem={handleAccountChange} dropDownTitles={accountTitles} toggleClose={plecibo}/>
                    </Grid>
                  } 
                    <DropDownButtonless itemList={warehouseList} handleSelectedItem={handleSelectedWarehouseInventory}dropDownTitles={warehouseTitles} toggleClose={plecibo}/>
                    <CurrentWarehouseShelfTable user= {userData} warehouseShelfsDisplayData={warehouseShelfsDisplayData} />
                    </>
                  }
                </Paper>
              </Grid>
              }
            

              {/* User Activity */}
              {
              // userData.accountViewer &&
              // <>

              // <Grid item xs={12} md={12} lg={12}>
              //   <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

              //   <Button onClick={toggleViewUserActivityTable}> {userActivityMessage} </Button> 
              //   {viewUserActivityTable &&
              //     <UserStats userDisplayStats={userDisplayStats} />
              //   }
              //   </Paper>
              // </Grid>

              //   {/* Account Activity */}
              // <Grid item xs={12} md={12} lg={12}>
              //   <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

              //   <Button onClick={toggleViewAccountActivityTable}> {accountActivityMessage} </Button> 
              //   {viewAccountActivityTable &&
              //     <AccountActivityStats accountDisplayStats={accountDisplayStats} />
              //   }
              //   </Paper>
              // </Grid>

              // </>
              }
              {/* Create Region */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateRegion}> {createRegionMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewCreateRegion &&
                    <CreateRegion toggleViewCreateRegion={toggleViewCreateRegion} accountList={accountList} headers={props.headers} user={userData} handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>
              {/* Create Markets */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateMarket}> {createMarketMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewCreateMarket &&
                    <CreateMarkets toggleViewCreateMarket={toggleViewCreateMarket} accountList={accountList} regionList={regionList} getRegionsList={getRegionsList} headers={props.headers} handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>

              {/* Create Shelf */}
              <Grid item xs={4} md={4} lg={4} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleViewCreateShelf}> {createShelfMessage} </Button> 
                  {/* need to be able to create a mobile user */}
                  {viewCreateShelf &&
                    <CreateShelf toggleViewCreateShelf={toggleViewCreateShelf} accountList={accountList}  getRegionsList={getRegionsList} warehouseList={warehouseList} getWarehouseList={getWarehouseList} headers={props.headers}  handleMessageBarOpen={handleMessageBarOpen} />
                  }
                </Paper>
              </Grid>

          
          {/* Read about services */}

          {false &&
          <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Button onClick={toggleEdonConnectServices} > {edonConnectServicesMessage} </Button> 
              {viewEdonConnectServices &&
                <EdonConnectServices />
              }
            </Paper>

          </Grid>
          }


            <Box sx={{justifyContent:'center', border:0}}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Box>

            </Grid>

        </Container>


        </Box>
      </Box>
    </ThemeProvider>
  );
}

