import React, { useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext.js';
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

function MessageBell() {
  const [anchorEl, setAnchorEl] = useState(null);
  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};

  // Example messages
  // const messages = [
  //   { id: 1, text: 'You have no messages at this time.' },
  //   { id: 2, text: 'New comment on your post.' },
  //   { id: 3, text: 'Reminder: Meeting at 3 PM.' },
  // ];

  const [messages, setMessages] = useState([]);
  const [messagesCount, setMessagesCount] = useState(0);
  React.useEffect(() => {
    getMessages();
  }, []);

  // build a function to get messages from the server
  const getMessages = async () => {
    var parameters = { accountcode: userData.accountCode}; 
    await axios
    .get(
        `/Messages/platformmessage`,
        { headers: headers, params: parameters }
        )
        .then(function async(response) {
          
          setMessages(response.data.messages);
          //count eh number of messages that have not been read
          setMessagesCount(response.data.unseen);
          
        })
        .catch(function (error) {
        if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");

        }
        if (error.response.status !== 401) {
          console.log(error.response.data.detail)
        }
        })
    };
  // Change seen status of message
  const updateMessages = async (messageId) => {
    const data = {
      messageId: messageId,
      property: "seen",
      status: true,
      accountcode: userData.accountCode
    };
    console.log(data);

    try {
      const response = await axios.patch(
        '/Messages/updateplatformmessage',
        data, // Data to be updated
        { headers: headers } // Configuration object with headers
      );
      // Handle the response
      console.log(response.data);
      // Optionally update state or perform other actions
      // setMessages(response.data.messages);
      // setMessagesCount(response.data.unseen);
    } catch (error) {
      if (error.response.status === 401) {
        alert("Your session has expired. Please login again.");
      } else {
        console.log(error.response.data.detail);
      }
    }
    };

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    //for each message, update the seen status
    messages.forEach((message) => {
      //if the message is not seen, update the status
      if (message.seen === false){
        updateMessages(message.id);
      }
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'message-popover' : undefined;

  return (
    <>
      <IconButton
        aria-label="notifications"
        aria-describedby={id}
        onClick={handleClick}
      >
        <Badge badgeContent={messagesCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography variant="h5" sx={{ p: 2 }}>
          Notifications
        </Typography>
        <Divider />
        <List sx={{ width: 300 }}>
          {messages.length > 0 ? (
            messages.map((messages) => (
              <ListItem key={messages.id} divider>
                <ListItemText primary={messages.content} />
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" sx={{ p: 2 }}>
              No new notifications.
            </Typography>
          )}
        </List>
      </Popover>
    </>
  );
}

export default MessageBell;
