import React from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext.js';
import MessageSnackBar from './MessageSnackBar.js';
import SimpleGrid from './SimpleGrid.js';

import { 
    Button, 
    Grid,
    Paper ,
    } from '@mui/material';

const WarehouseInfo = (props) => {
    //SECTION USER CONTEXT and HEADERS
    const userData = useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
    // !SECTION

    //SECTION Message Bar Controls
      const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
      const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
      const [ messageSeverity, setMessageSeverity ] = React.useState('success');
     // ! this is the function that is called to open the message bar

      const handleMessageBarClosing = () => {
        setMessageBarOpen(false);
      };
      //!SECTION

    //SECTION Component Controls
    const itemMessage = 'Warehouse Information';

    const toggleItems = () => {
      if (viewItems){
          setViewItems(false);
          setViewItemsMessage(`View ${itemMessage}`);
      }else{
          // getWarehouseList(userData.email)
          setViewItems(true);
          setViewItemsMessage(`Hide ${itemMessage}`);
      };
  } ;

    const [ viewItems, setViewItems ] = React.useState(false);
    const [ viewItemsMessage, setViewItemsMessage ] = React.useState(`View ${itemMessage}`);

    //!SECTION


  // SECTION Add new capabilities here
    React.useEffect(() => {
        getCurrentWarehouseTable(userData);
    }, []);

    const [ currentWarehousesDisplayData, setCurrentWarehousesDisplayData ] = React.useState([]);

    const getCurrentWarehouseTable = (userData) => {

        axios.get(`Dashboard/currentwarehouselocations/${userData.accountCode}`, 
          {headers: headers})
        .then(function (response) {
          setCurrentWarehousesDisplayData(response.data.message)
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            setMessageBarMessage(`Sorry! User info is not available at this time. ${error.message}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
        .then(function () {
          // always executed
        });
      }

    //!SECTION

    //SECTION GRID variables

    const visibleColumns = {
      id: false,
      serialNum: false,
      };

    const filterModel = {
      items: [
      ]
    };
    // some things are defined in the grid component
    const pinnedModel = {left: ['Item Number','Order Discrepancy']};
    const sortModel = [{ field: "Order Discrepancy"},];


    const nonAdminUser = userData.userRole !== 10 ? true : false;
    const  currentWarehouses  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'warehouseName', headerName: 'Warehouse', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'warehouseNumber', headerName: 'Warehouse Number', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'address', headerName: 'Address', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'city', headerName: 'City', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'state', headerName: 'State', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'zip', headerName: 'Zip', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'market', headerName: 'Market', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'region', headerName: 'Region', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'description', headerName: 'Location Description', width:300,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'inventoryCount', headerName: 'Inventory', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'descrepanciesCount', headerName: 'Discrepancies', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'type', headerName: 'Warehouse Type', width:275, headerAlign: 'center', align: 'center',  editable: false},
      ], 
      rows: currentWarehousesDisplayData
      };


      //!SECTION

      

  return (  

        <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ alignContent: "center", border: 0 }}
      >
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
        >
          <Button onClick={toggleItems}>
            {" "}
            {viewItemsMessage}
          </Button>

          {/* Warehouse Filters */}
          

          {viewItems && 
          <>
        <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
        
        <Grid
          container
          justifyContent={"center"}
          sx={{marginBottom:4, marginTop:2}}
          >


          {/* //SECTION - Grid */}
          <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{ alignContent: "center", border: 0 }}
          >
        <SimpleGrid 
        gridColumns={currentWarehouses.columns}
        gridData={currentWarehouses.rows}
        visibleColumns={visibleColumns}
        sortModel={sortModel}
        pinnedColumns={pinnedModel}
        />
          </Grid>
          {/* //!SECTION */}


          </Grid>

      </>
    }
        </Paper>
      </Grid>
  </>


  );
};
export default WarehouseInfo;