import React from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext.js';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MessageSnackBar from './MessageSnackBar.js';

import MapIcon from '@mui/icons-material/Map';

import { 
    Button, 
    Grid,
    Paper, 

  } from '@mui/material';

import Map from './Map.js';

const itemNumbers = [
    { label: '41X1147' },
    { label: '41X2901' },
    { label: '41X1178' },

];



const Mapping = (props) => {
    //SECTION USER CONTEXT and HEADERS
    const userData = useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
    // !SECTION

    //SECTION Message Bar Controls
      const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
      const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
      const [ messageSeverity, setMessageSeverity ] = React.useState('success');
      const handleMessageBarClosing = () => {
        setMessageBarOpen(false);
      };
      //!SECTION

    //SECTION Component Controls
    const itemMessage = 'Maps';
    const toggleItems = () => {
      if (viewItems){
          setViewItems(false);
          setViewItemsMessage(`${itemMessage}`);
          // console.log("user data", userData.name)
      }else{
          // getWarehouseList(userData.email)
          setViewItems(true);
          setViewItemsMessage(`Hide ${itemMessage}`);
      };
  } ;
    const [ viewItems, setViewItems ] = React.useState(false);
    const [ viewItemsMessage, setViewItemsMessage ] = React.useState(`View ${itemMessage}`);
    //!SECTION
    //SECTION - map controls
    const [ mapCenter, setMapCenter ] = React.useState([-98.5795, 39.8282]);
    const [ mapZoom, setMapZoom ] = React.useState(3);

    //!SECTION


  return (  

      <>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{ alignContent: "center", border: 0 }}
      >
        <Paper
          sx={{ p: 2, display: "flex", flexDirection: "column" }}
        >
          <Button 
          onClick={toggleItems}
          startIcon={<MapIcon size="large" />}
          >
            {" "}
            {viewItemsMessage}
          </Button>

          {/* Component Items */}
          {viewItems &&
          <>
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} /> 
          
          {/* <Grid container direction="row" marginTop={5} marginBottom={5} sx={{justifyContent: "space-between", border:1, borderStyle: 'dotted', borderRadius: '5px'  }}> */}
          <Grid
            container
            marginTop={5}
            sx={{
              justifyContent: "center",
              alignContent: "flex-start",
              p: 0,
              border: 1,
              borderStyle: "dotted",
              borderRadius: "5px",
              overflow: 'hidden'
            }}
          >
        {false &&
          <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{  
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            border: 0,
            borderStyle: "dotted",
            borderRadius: "5px",
            margin: 1

            }}
          >
        <Autocomplete
        disablePortal
        options={itemNumbers}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Part Number" />}
        />
            
          </Grid>    
        }

          <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{  
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            border: 0,
            borderStyle: "dotted",
            borderRadius: "5px",
            margin: 0

            }}
          >
            <Map mapCenter={mapCenter} mapZoom={mapZoom} />
            
          </Grid>    
                    

          </Grid>
        </>
    }
        </Paper>
      </Grid>
  </>


  );
};
export default Mapping;