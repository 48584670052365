import React from 'react';
import { useContext } from 'react';
import { createPortal } from 'react-dom';
import { UserContext } from '../context/UserContext.js';
import { Grid, Paper } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import ReactMapboxGl, { Layer, Feature, Marker, Popup } from "react-mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import edonconnectlogo from '../img/edonConnectLogoSmall.png';
import axios from 'axios';

const Map = () => {
    //SECTION USER CONTEXT and HEADERS
    const userData = useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
    // !SECTION
    
    const MapView = React.memo(ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiZ29vZG1vbnN0ZXIiLCJhIjoiY202aHlkdXRpMDJwdDJsb3JycWZhMWV5ciJ9.SB84drGwNAQyliOyxWy4Zg',
    }));
    

//   const mapRef = React.useRef();
//   const mapContainerRef = React.useRef();


  //SECTION - Map Controls
  const [ markers, setMarkers ] = React.useState([]);
  const [mapZoom, setMapZoom] = React.useState([10]); 
  const [popupCoordinates, setPopupCoordinates] = React.useState([]);
  const [popupInfo, setPopupInfo] = React.useState({ lng: -84.353435, lat: 33.957536, text: 'Edon Connect' });

  React.useEffect(() => {
    getmarkers();
    }, []);
    

  //get the markers from the API
  const getmarkers = async (user) => {
    var parameters = { itemNumber: "All"}; 
    await axios
    .get(
        `/Warehouses/warehousemappinginfo`,
        { headers: headers, params: parameters }
        )
        .then(function async(response) {
        //   console.log(response.data.message)
        setMarkers(response.data.message)
        })
        .catch(function (error) {
        if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");

        }
        if (error.response.status !== 401) {
            console.log(error.response.data.detail)
        }
        })
    };
    const [selectedMarker, setSelectedMarker] = React.useState(null);
    const handleMarkerClick = React.useCallback((marker) => {
        // console.log('Marker clicked:', marker);
        setSelectedMarker(marker);
        setPopupCoordinates(marker.coordinates);
      }, []);

    //   const handleZoomEnd = React.useCallback((map) => {
    //     setMapZoom([map.getZoom()]);
    //   }, []);
    
      const CustomMarker = React.memo(({ marker }) => {
        return (
          <div onClick={() => handleMarkerClick(marker)} style={{ cursor: 'pointer' }}>
            <img src={edonconnectlogo} alt="Marker" style={{ width: '20px', height: '20px' }} />
          </div>
        );
      });

      const MemoizedMapView = React.memo(MapView);

    //   const Popup = ({ coordinates, marker }) => {
    //     return createPortal(
    //       <div
    //         style={{
    //           position: 'absolute',
    //           left: `${coordinates[0]}px`,
    //           top: `${coordinates[1]}px`,
    //           backgroundColor: 'white',
    //           padding: '5px',
    //           borderRadius: '3px',
    //           boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    //         }}
    //       >
    //         <div style={{ color: 'black' }}>{marker.warehouseName}</div>
    //         <div style={{ color: 'black' }}>{marker.inventoryCount}</div>
    //       </div>,
    //       document.body
    //     );
    //   };



//   const CustomMarker = ({ marker }) => {
//     return (
//       <div style={{ position: 'relative', textAlign: 'center' }}>
//         <img src={edonconnectlogo} alt="Marker" style={{ width: '20px', height: '20px' }} />
//         <div style={{  padding: '2px 5px', borderRadius: '3px', position: 'absolute', top: '25px', left: '50%', transform: 'translateX(-50%)', whiteSpace: 'nowrap' }}>
//           <div style={{ color: 'black' }}>{marker.warehouseName}</div>
//           <div style={{ color: 'black' }}>{marker.inventoryCount}</div>
//         </div>
//       </div>
//     );
//   };

// !SECTION
  





    // const onStyleLoad = (map) => {
    //     console.log('Map style loaded:', map.getStyle());
    //     const currentIcon = "'dot-9'";
    //     // List all available icons
    //     const availableIcons = map.listImages();

    //     // see if current icon is inn available icons
    //     if (availableIcons.includes(currentIcon)) {
    //         console.log('Current icon is available');
    //         }
    
    //     // Ensure the icon image is added to the map
    //     map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (error, image) => {
    //       if (error) {
    //         console.error('Error loading image:', error);
    //         return;
    //       }
    //       map.addImage('custom-marker', image);
    //       console.log('Custom marker image added successfully');
    
    //       // Check if the image is available
    //       if (map.hasImage('custom-marker')) {
    //         console.log('Custom marker image is available');
    //       } else {
    //         console.error('Custom marker image is not available');
    //       }
    //     });
        
    //   };



//   React.useEffect(() => {
//     mapboxgl.accessToken = 'pk.eyJ1IjoiZ29vZG1vbnN0ZXIiLCJhIjoiY202YjdramlmMDVsazJrcTJiYmdrdjJubSJ9.wgDfq68Pkcg7jrBQfqbW8Q';
    

//     if (mapContainerRef.current) {
//       mapRef.current = new mapboxgl.Map({
//         container: mapContainerRef.current, // Ensure this is a valid DOM element
//         style: 'mapbox://styles/mapbox/streets-v11', // Add a style URL
//         center: props.mapCenter, // Initial map center in [lng, lat]
//         zoom: props.mapZoom, // Initial map zoom level
//       });
//     }

//     return () => {
//       if (mapRef.current) {
//         mapRef.current.remove();
//       }
//     };
//   }, []);

//   const mapStyle = {
//     flex: 1
//   };

  return (
    // <>
    <Grid container spacing={0}>
      
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{  
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          border: 0,
          borderStyle: "dotted",
          borderRadius: "5px",
          marginTop: 1,
          padding: 1,
          paddingLeft: 1,

        }}
      >
      <MemoizedMapView
        style='mapbox://styles/mapbox/streets-v9'
        center={[-98.5795, 39.8282]}
        zoom={[3]}
        containerStyle={{
            width: '100%', // Set the width of the map
            height: '600px', // Set the height of the map
            minHeight: '600px' // Ensure the minimum height is 600px
        }}
        // onZoomEnd={handleZoomEnd} // Update zoom level on change
      >
        
        {
        markers.map(marker => (
          <Marker
            key={marker.id}
            coordinates={marker.coordinates}
            anchor="bottom"
          >
            <CustomMarker marker={marker} /> {/* Use the custom marker component */}
          </Marker>
        ))
        }
        {selectedMarker && (
          <Popup
            coordinates={selectedMarker.coordinates}
            offset={{
              'bottom-left': [12, -38], 'bottom': [0, -38], 'bottom-right': [-12, -38]
            }}
          >
            <div>
              <div style={{ color: 'black' }}>{selectedMarker.warehouseName}</div>
              <div style={{ color: 'black' }}>{selectedMarker.inventoryCount}</div>
            </div>
          </Popup>
        )}

                {/* <Layer 
                    type="circle"
                    id="marker" 
                    >
                    
                    <Feature 
                    coordinates={[-84.353435, 33.957536]}
                    onClick={() => setPopupInfo({ lng: -84.353435, lat: 33.957536, text: 'Edon Connect' })} />
                  {popupInfo && (
                    <Popup
                      coordinates={[popupInfo.lng, popupInfo.lat]}
                      offset={[0, -10]}
                      onClose={() => setPopupInfo(null)}
                    >
                      <div>{popupInfo.text}</div>
                    </Popup>
                  )}
        
                </Layer> */}
        </MemoizedMapView>
        {/* {selectedMarker && popupCoordinates && (
        <Popup coordinates={popupCoordinates} marker={selectedMarker} />
      )} */}



      </Grid>
    </Grid>
    // </>
  );
};

export default Map;